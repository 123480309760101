import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlogHero from '../../components/sections/blog-hero';
import { BlogPost } from '../../models/blog-post';
import SEO from '../../components/seo';
import './articlestyle.css';

const TemplateBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      fakeIDMcLovin: file(relativePath: { eq: "blog/fake-id-post.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const blogHeroPost: BlogPost[] = [
    {
      heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostr',
      miniDescription: 'This should really only be about 5 words',
      image: data.fakeIDMcLovin.childImageSharp.fluid,
      link: '/get-pricing',
    },
  ];

  return (
    <div>
      <SEO
        title="Will Fake IDs Pass ID Scanners?"
        description="The front line for bars and nightclubs. Spot minors, identify flagged patrons and catch fake IDs. 24/7 support. Hear what venues are saying about Patronscan’s ID scanners for bars"
        socialImg="patronscan-social-blog-2.jpg"
      />
      <div className="flex justify-center mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg">
        <BlogHero blogMeta={blogHeroPost}></BlogHero>
      </div>
      <div className="flex justify-center">
        <div className="mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg article-container">
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            Do Fake IDs pass ID scanners?
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Fake ID vendors often advertise their counterfeits as “scannable.” So it’s reasonable to
            ask, whether or not a fake ID will realistically be caught by an ID scanner.<br></br>
            <br></br>
            To answer this, you'll need to understand understand the technology used by different ID
            scanners and the tools available to forgers to circumnavigate automated tests.<br></br>
            <br></br>
            The most popular of these tests verifies the barcode or magnetic strip on an ID.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            Barcodes are for Data Collection
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Most ID scanners verify an ID by reading the information on the barcode, or magnetic
            strip, and checking to see if the data is returned in the correct format.<br></br>
            <br></br>
            Unfortunately, these features are primarily used as a means to allow ID scanners to
            quickly extract information from an ID and aren’t designed as strong security features.
            <br></br>
            <br></br>
            Fabricating barcodes and magnetic strips with properly encoded identity information is
            fairly simple with the required technologies, and encoding schemes, readily available on
            the internet.<br></br>
            <br></br>
            While it’s possible to catch fake IDs using simple ID scanners, it’s uncommon for
            forgers to craft IDs with faulty barcodes or magnetic strips. Counterfeit IDs sold
            through the most popular online platforms will all return as valid when scanned.
            <br></br>
            <br></br>
            The majority of ID scanners used today rely solely on these tests alone, most fake IDs
            in circulation are passing as true when scanned.<br></br>
            <br></br>
            To effectively catch fakes IDs, you need technology to analyze the face of the ID to
            identify the slight discrepancies in the more sophisticated security features built into
            government-issued IDs.<br></br>
            <br></br>
            By comparing the imagery, fonts and spacing of scanned IDs against a real ID template
            using pixel-level processing, an ID scanner can accurately detect fake IDs. Few are able
            to achieve this<br></br>
            <br></br>
            This type of analysis is referred to as pattern matching.<br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            How do ID Scanners Effectively Catch Fake IDs?
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Issuers of official identity documents use intricate designs and proprietary fonts as
            key security features when creating IDs to make them virtually impossible to duplicate
            perfectly.<br></br>
            <br></br>
            The fine lines, unique typefaces, microtext and complex imagery found on an ID require
            securely-held technologies to reproduce which aren’t readily available to illegitimate
            print shops.<br></br>
            <br></br>
            When these details are poorly faked, they are easily caught by the trained eye. But more
            advanced fake IDs will pass most human verifications and can only be caught by analyzing
            the ID in such granularity that often only a handful of pixels provide tell-tale signs
            of a fake.<br></br>
            <br></br>
            It’s why Patronscan relies on its proprietary pattern-matching technology to micro
            analyze 4,500 different IDs to accurately verify IDs. Pattern-matching is the most
            common way fake IDs fail to pass our ID scanners.<br></br>
            <br></br>
            With access to these near-perfect fake IDs becoming easier than ever on the dark web,
            counterfeit identity documents are fueling persistent yearly increases in identity fraud
            losses among businesses worldwide.<br></br>
            <br></br>
            Holograms and fluorescent overlays are another layer of security that allow ID scanners
            to conduct a third test: how an ID's reflective qualities behave under different
            lighting conditions.<br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            Validating Holograms and Fluorescent Overlays
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Holograms and fluorescent overlays are used as an added layer of security that can be
            tested by shining white light on the ID or placing it under UV light.<br></br>
            <br></br>
            As these security features are easily verified with low-tech solutions, the modern
            counterfeiter will copy these features as closely as possible. Forgers either try to
            reproduce these security features or port over authentic versions from real IDs to trick
            these tests.<br></br>
            <br></br>
            Whether or not a fake ID will pass an ID scanner’s lighting test depends on the quality
            of the fake ID and the sophistication of the ID scanner. Patronscan uses custom lighting
            and hardware sensors that analyze the reflections of an ID to assist in determining
            authenticity.<br></br>
            <br></br>
            Holograms and florescent overlays are replicated by more sophisticated fake IDs, so
            relying on these tests alone can provide a false sense of security.<br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            It's All in the Scanning Engine
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Fake IDs will still pass ID scanners conducting all three tests if the{' '}
            <i>scanning engine</i> isn’t strong enough. The scanning engine is the software that
            processes the information collected by a scan to validate the ID.<br></br>
            <br></br>A reliable scanning engine takes time to build, is constantly updated and uses
            millions of scans to build algorithms that accurately fake ID detection. Without a
            proper scanning engine, false positives are constant and newer versions of fake IDs will
            end up passing these tests.<br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            Will a Fake ID be Detected by an ID scanner?
          </h2>
          <p className="article-body text-sb lap:text-mb">
            To catch fake IDs with an ID scanner, you need the right set of ingredients. Reliable
            tests take a look at the smallest details on an ID to check for accuracy.<br></br>
            <br></br>
            Pattern-matching and a strong scanning engine, are the most important components for
            verifying IDs. Even ID scanners with the right hardware mix often fail to keep their
            libraries updated, leading to reliability issues, false positives and unreadable IDs.
            <br></br>
            <br></br>
            Taken all the above into consideration, will a fake ID scan?<br></br>
            <br></br>
            For most ID scanners, the unfortunate answer is: <i>yes</i>. With Patronscan's
            proprietary scanning technology? Unlikely.<br></br>
            <br></br>
          </p>
          <p>
            If you need accurate fake ID detection for your business,{' '}
            <a href="/talk-to-an-expert" className="text-blue-old">
              book a demo
            </a>{' '}
            today and see why Patronscan is the trusted choice in ID verification.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemplateBlog;
